.citation a, .pagebreak a, .columnbreak a {
    text-decoration: none;
}

.citation {
    font: -apple-system-body;
    font-weight: normal;
    font-size: inherit;
    height: 1.3em;
    display: inline-block;
    position: relative;
    text-indent: .2em;
    background-color: #CCFFBB;
    border: 1px dotted black;
    padding-right: .2em;
    margin: 0em .1em;
    top: -2px;
    vertical-align: middle;
}

/* .citation > a[onclick*="gs("] { */
.citation > #gs {
    position: relative;
    top: -1px;
    display: inline-block;
}

/* li.footnote .citation > a[onclick*="gs("] { */
li.footnote .citation > #gs {
    top: -0.15em;
}

/* span.footnote .citation > a[onclick*="gs("] { */
span.footnote .citation > #gs {
    top: .6em;
}

/* .citation > a[onclick*="sx("] { */
.citation > #sx {
    display: inline-block;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/sync_17@2x.png) no-repeat scroll right 1px;
    background-size: contain;
    width: 1.5em;
    height: 1.159em;
    margin-right: .2em;
}

.citation.dest {
    background-color: orange;
}

li.footnote .citematch,
.footnotes .footnote .citematch,
.footnote .citematch,
.citematch {
    background-color: orange;
}

.ccontainer {
    display: inline-block;
    text-indent: 0;
}

.ccontainer.lparen:before {
    content: "(";
}

.ccontainer.ldquo:before {
    content: "“";
}

.ccontainer.lbrack:before {
    content: "[";
}

.ccontainer.lparenbrack:before {
    content: "([";
}

.ccontainer.lmdash:before {
    content: "—";
}

.ccontainer.rcomma:after {
    content: ",";
}

.ccontainer.rsemi:after {
    content: ";";
}

.ccontainer.rcolon:after {
    content: ":";
}

.ccontainer.rcolonbrack:after {
    content: ":]";
}

.ccontainer.rquest:after {
    content: "?";
}

.ccontainer.rdot:after {
    content: ".";
}

.ccontainer.rdotrdquo:after {
    content: ".”";
}

.ccontainer.rdotparen:after {
    content: ".)";
}

.ccontainer.rdotparenrdquo:after {
    content: ".)”";
}

.ccontainer.rdotparenbrack:after {
    content: ".)]";
}

.ccontainer.rdotbrack:after {
    content: ".]";
}

.ccontainer.rdotbrackrdquo:after {
    content: ".]”";
}

.ccontainer.rdotbrackbrack:after {
    content: ".]]";
}

.ccontainer.rldquo:after {
    content: "“";
}
.ccontainer.rrsquordquo:after {
    content: "’”";
}

.ccontainer.rparen:after {
    content: ")";
}

.ccontainer.rparencomma:after {
    content: "),";
}

.ccontainer.rparencommardquo:after {
    content: "),”";
}

.ccontainer.rparensemi:after {
    content: ");";
}

.ccontainer.rparencolon:after {
    content: "):";
}

.ccontainer.rparenbang:after {
    content: ")!";
}

.ccontainer.rparenquest:after {
    content: ")?";
}

.ccontainer.rparenquestrdquo:after {
    content: ")?”";
}

.ccontainer.rparendot:after {
    content: ").";
}

.ccontainer.rparendotapos:after {
    content: ").'";
}

.ccontainer.rparendotaposquote:after {
    content: ").'\"";
}

.ccontainer.rparendotquote:after {
    content: ").\"";
}

.ccontainer.rparendotrdquo:after {
    content: ").”";
}

.ccontainer.rparenaposquote:after {
    content: ")'\"";
}

.ccontainer.rparenquote:after {
    content: ")\"";
}

.ccontainer.rparenquotedot:after {
    content: ")\".";
}

.ccontainer.rparendotbrack:after {
    content: ").]";
}

.ccontainer.rparenrdquo:after {
    content: ")”";
}

.ccontainer.rparenbrack:after {
    content: ")]";
}

.ccontainer.rparenquestrdquomdash:after {
    content: ")?”—";
}

.ccontainer.rparenrdquomdash:after {
    content: ")”—";
}

.ccontainer.rparenmdash:after {
    content: ")—";
}

.ccontainer.rbrack:after {
    content: "]";
}

.ccontainer.rbrackcomma:after {
    content: "],";
}

.ccontainer.rbrackcommardquo:after {
    content: "],”";
}

.ccontainer.rbracksemi:after {
    content: "];";
}

.ccontainer.rbrackcolon:after {
    content: "]:";
}

.ccontainer.rbrackdot:after {
    content: "].";
}

.ccontainer.rbrackdotrdquo:after {
    content: "].”";
}

.ccontainer.rbrackdotbrack:after {
    content: "].]";
}

.ccontainer.rbrackrdquo:after {
    content: "]”";
}

.ccontainer.rbrackrdquoquest:after {
    content: "]”?";
}

.ccontainer.rbrackparen:after {
    content: "])";
}

.ccontainer.rbrackparencomma:after {
    content: "]),";
}

.ccontainer.rbrackparensemi:after {
    content: "]);";
}

.ccontainer.rbrackparendot:after {
    content: "]).";
}

.ccontainer.rbrackparenmdash:after {
    content: "])—";
}

.ccontainer.rbrackmdash:after {
    content: "]—";
}

.ccontainer.rbrackellip:after {
    content: "]…";
}

.ccontainer.rmdash:after {
    content: "—";
}

.hg1, span#search.hg1 {
    color: black;
    background-color: #ffff66;
}

.hg2, span#search.hg2 {
    color: black;
    background-color: #a0ffff;
}

.hg3, span#search.hg3 {
    color: black;
    background-color: #99ff99;
}

.hg4, span#search.hg4 {
    color: black;
    background-color: #ff9999;
}

.hg5, span#search.hg5 {
    color: black;
    background-color: #ff66ff;
}

.hg6, span#search.hg6 {
    color: white;
    background-color: #880000;
}

.hg7, span#search.hg7 {
    color: white;
    background-color: #00aa00;
}

.hg8, span#search.hg8 {
    color: white;
    background-color: #886800;
}

.hg9, span#search.hg9 {
    color: white;
    background-color: #004699;
}

.hg10, span#search.hg10 {
    color: white;
    background-color: #990099;
}