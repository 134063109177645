div#aboutcontent .talktitle {
    font-size: .75em;
    color: #444;
    line-height: 15px;
}

div#aboutcontent ul.referencesblock li .listentalk,
div#aboutcontent ul.referencesblock li .watchtalk {
    font-size: .6em;
    color: #444;
    text-align: center;
    position: absolute;
    opacity: 1;
    left: 4px;
    bottom: 0;
}

div#aboutcontent ul.referencesblock li a.listenlink,
div#aboutcontent ul.referencesblock li a.watchlink {
    position: absolute;
    width: 40px;
    top: .2em;
    z-index: 2;
    border: none;
}

div#aboutcontent ul.referencesblock li a.listenlink {
    right: 10px;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/soundIcon.png) no-repeat;
    background-position: 11.5px 5px;
    height: 2.3em;
}

div#aboutcontent ul.referencesblock li a.watchlink {
    right: 50px;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/filmIcon.png) no-repeat;
    background-position: 12.5px 7px;
    height: 2.3em;
}

div#aboutcontent {
    color: #111;
    background-color: #ffffff;
    font: -apple-system-body;
}

div#aboutcontent img {
    text-align: center;
}

div#aboutcontent h1 {
    font-size: 20px;
    text-align: center;
}

div#aboutcontent h2 {
    font: -apple-system-headline;
    text-align: center;
}

div#aboutcontent h2.about {
    font-size: 20px;
    line-height: 22px;
}

div#aboutcontent h2.sect {
    background-color: #f0e4da;
    font: -apple-system-headline;
    margin-left: -18px;
    margin-right: -18px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-repeat: repeat-x;
    font-size: 20px;
    line-height: 22px;
}

div#aboutcontent h3 {
    text-align: center;
    font: -apple-system-subhead;
    font-weight: normal;
    font-size: 14px;
    color: #000;
    line-height: 20px;
    padding-bottom: 5px;
}

div#aboutcontent a:link, div#aboutcontent a:visited, div#aboutcontent a:active, div#aboutcontent a:hover {
    color: #101060;
}

div#aboutcontent table, div#aboutcontent th, div#aboutcontent td {
    border: 1px solid black;
}

div#aboutcontent table {
    color: #000000;
    background-color: #e0e0e0;
    border-collapse: collapse;
}

div#aboutcontent th {
    background-color: lightblue;
}

div#aboutcontent td {
    vertical-align: top;
    padding-left: .4em;
    padding-right: .4em;
}

div#aboutcontent div.list {
    position: relative;
    padding: .5em 1em;
    margin-left: 1em;
    margin-bottom: 1em;
    border: 1px solid #404040;
    background-color: white;
    color: #202020;
    width: 20em;
    font-size: 120%;
}

div#aboutcontent a.button, div#aboutcontent a.button:visited {
    display: inline-block;
    background-color: #f0e4da;
    width: 100px;
    height: 22px;
    padding-top: 7px;
    padding-bottom: 5px;
    text-align: center;
    border: 1px solid #aaa;
    font: -apple-system-body;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    color: #000;
    text-decoration: none;
    margin-bottom: 5px;
}

div#aboutcontent div.alink {
    display: block;
    width: 100px;
    height: 29px;
    color: #fff;
}

div#aboutcontent div.alink a {
    color: #101060;
}

div#aboutcontent #banner {
    background-image: url(https://scriptures.byu.edu/static/homepage/media/images/wood-768.png);
    background-repeat: repeat-x;
    margin-left: -18px;
    margin-right: -18px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 0px;
}

div#aboutcontent #btitle {
    background-color: #282828;
    margin-left: -18px;
    margin-right: -18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

div#aboutcontent #bannerImg, div#aboutcontent #bannerTitleImg {
    width: 100%;
    height: auto;
}

@media screen and (max-device-width: 480px) {
    div#aboutcontent #bannerImg {
        max-width: 320px;
    }

    div#aboutcontent #bannerTitleImg {
        max-width: 246px;
    }
}

@media screen and (min-device-width: 481px) {
    div#aboutcontent #bannerImg {
        max-width: 703px;
    }

    div#aboutcontent #bannerTitleImg {
        max-width: 541px;
    }
}

p.new {
    background-color: lightblue;
    margin: 0;
    padding: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin-left: -18px;
    margin-right: -18px;
    padding: .5em;
}