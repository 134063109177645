div#jContent {
    font: -apple-system-body;
    margin: 0;
    padding: 0 0 4em;
    background-color: #ffffff;
    color: #201500;
}

div#jContent a:link, div#jContent a:hover, div#jContent a:visited, div#jContent a:active {
    color: blue;
}

div#jContent .italic {
    font-style: italic;
}

div#jContent .bold {
    font-weight: bold;
}

div#jContent .small-cap {
    font-variant: small-cap;
}

div#jContent div.pageHeader, div#jContent div.speaker, div#jContent div.date {
    display: none;
}

div#jContent div.title {
    font-size: 110%;
}

div#jContent div.subtitle {
    font-size: 85%;
}

div#jContent div.discourseBody {
    padding-left: 2em;
    padding-right: 2em;
}

div#jContent div.discourseInfo {
    font-size: 85%;
}

div#jContent div.discourseHeader {
    background-color: #f0e4da;
    border-bottom: 1px solid #666;
    margin: 0 0 1em;
    padding: 1em 2em;
}

div#jContent div.indent {
    padding-left: 1.5em;
    padding-right: 1.5em;
}

div#jContent .paragraph {
    display: block;
    text-indent: 1.5em;
}

div#jContent .pagebreak, div#jContent .columnbreak {
    background-color: #FFFFBB;
    border: 1px dotted black;
    color: black;
    display: inline;
    font-size: 85%;
    font-weight: normal;
    margin-right: .3em;
    padding-bottom: .1em;
    padding-left: .2em;
    padding-right: .2em;
    text-align: left;
    white-space: nowrap;
}

div#jContent .columnbreak {
    background-color: #CCFFFF;
}

div#jContent .hyphen {
    display: inline;
    font-weight: normal;
}

div#jContent .impdf {
    display: inline-block;
    background-image: url(https://scriptures.byu.edu/static/homepage/media/images/pdf_17.png);
    background-position: 0 -.2em;
    background-size: 1em 1.2em;
    background-repeat: no-repeat;
    height: 1.25em;
    width: 1em;
    margin-left: .3em;
    margin-right: .1em;
    vertical-align: middle;
}

div#jContent .imhbll {
    display: inline-block;
    background-image: url(https://scriptures.byu.edu/static/homepage/media/images/hbll_17.png);
    background-position: 0 -.2em;
    background-size: 1em 1.2em;
    background-repeat: no-repeat;
    height: 1.25em;
    width: 1em;
    margin-left: .1em;
    margin-right: .1em;
    vertical-align: middle;
}

@media print {
    div#jContent .impdf, div#jContent .imhbll {
        display: none;
    }
}