div.gcera {
    font: -apple-system-body;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #201500;
}

div.gcera a:link, div.gcera a:hover, div.gcera a:visited, div.gcera a:active {
    color: blue;
}

div.gcera {
    padding: 1em 2em 4em 2em;
}

div.gcera a {
    text-decoration: none;
}

div.gcera .gctitle {
    font: 36pt/42pt -apple-system-font;
    font-weight: 100;
    margin: 0;
    padding: .4em 0 .2em 0;
}

div.gcera .gcspeaker {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: smaller;
}

div.gcera .gcspkpos, div.gcera .gcspkpos2 {
    margin: 0;
    padding: 0;
    font-style: italic;
    font-size: smaller;
}

div.gcera .gcbib {
    font-size: smaller;
    border-top: 1px solid black;
    border-bottom: 1px solid #9ea0a2;
    padding: .3em .6em;
    margin-top: 2em;
    margin-bottom: 2em;
    background-color: #f0e4da;
}

div.gcera p.quote {
    padding-left: 2em;
    padding-right: 2em;
    font-size: 95%;
}

div.gcera .poem {
    font-style: italic;
}

div.gcera .poem2 {
}

div.gcera .credit {
    text-align: right;
    margin-right: 4em;
}

div.gcera .biblio {
    padding-left: 2em;
    padding-right: 2em;
}