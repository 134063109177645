div.body {
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

div#content.two-col blockquote {
    margin: 0;
    border-left: none;
}

div#content.two-col a {
    color: #201500;
}

div#content.two-col .label {
    background-color: #ffffff;
    color: #201500;
    padding: 0;
    font-weight: normal;
    line-height: single;
    text-shadow: none;
    font-size: 100%;
}

div#content.two-col p {
    margin: 0 0 1em;
}

div#content.two-col li {
    line-height: 1.4em;
}

div#content.two-col ul, div#content.two-col ol {
    margin: 0;
}

div#content.two-col {
    font: -apple-system-body;
    margin: 0;
    padding: 2rem 4rem 4rem 4rem;
    background-color: #ffffff;
    color: #201500;
}

div#content.two-col a:link, div#content.two-col a:hover, div#content.two-col a:visited, div#content.two-col a:active {
    color: blue;
}

div#content.two-col a {
    text-decoration: none;
}

div#content.two-col .language {
    font-style: italic;
}

div#content.two-col h1 {
    font: 36pt/42pt -apple-system-font;
    font-weight: 100;
    margin: 0;
    padding: .4em 0 .2em 0;
}

div#content.two-col h2 {
    font-size: 117%;
}

div#content.two-col h2.author {
    font: -apple-system-body;
    font-size: smaller;
    margin: 0;
    padding: 0;
}

div#content.two-col div.topic h2 {
    text-align: center;
}

div#content.two-col .strong {
    font-weight: bold;
}

div#content.two-col .emphasis {
    font-style: italic;
}

div#content.two-col .underline {
    text-decoration: underline;
}

div#content.two-col .small {
    font-size: 85%;
}

div#content.two-col div.byline {
    padding-bottom: 1em;
}

div#content.two-col div.byline p {
    font-weight: bold;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

div#content.two-col div.byline p+p {
    font-weight: normal;
}

div#content.two-col blockquote.intro {
    max-width: 480px;
    xmargin: 0px auto -1px auto;
    padding: 1em 0;
    overflow: hidden;
}

div#content.two-col #primary .intro img.img-decor {
    float: left;
    margin: 0 1em 1em 0;
}

div#content.two-col .img-decor, div#content.two-col div.figure > img, blockquote.intro > img {
    padding: 1px;
    background-color: white;
    border: 1px solid #9ea0a2;
    -moz-box-shadow: 2px 4px 13px #bbb;
    -webkit-box-shadow: 2px 4px 13px #bbb;
    box-shadow: 2px 4px 13px #bbb;
    border-image: initial;
    margin-bottom: 1em;
}

div#content.two-col div.kicker, div.body p.kicker {
    font-family: serif;
    font-size: 130%;
    font-style: italic;
    color: #633600;
}

div#content.two-col span.footnote {
    font: -apple-system-body;
	background-color: #f0e4da;
	font-size: inherit;
}

div.body footer.notes p.title {
    font-weight: bold;
    font-size: 130%;
}

div#content.two-col #references li.footnote {
    font-size: smaller;
}

div#content.two-col #references li {
    list-style: none;
    text-indent: -3em;
    margin-left: 3em;
}

div#content.two-col #references ol {
    padding-left: 0px;
    -webkit-padding-start: 0px;
    padding-right: 2em;
}

div#content.two-col #references .label {
    display: inline-block;
    width: 2em;
    text-align: right;
}

div#content.two-col #references p {
    text-indent: 0;
}

div#content.two-col #references .label + p {
    display: inline;
    padding-left: .1em;
    text-indent: -3em;
}

div#content.two-col #primary ul.bullet {
    padding-right: 2em;
    padding-left: 2em;
}

div#content.two-col #primary ul.bullet li .label {
    display: none;
}

div#content.two-col ul.content-list.noMarker li {
    list-style-type: none;
}

div#content.two-col .figure ul, div#content.two-col .figure ol {
    -webkit-padding-start: 0;
}

div#content.two-col #primary ol.number {
    padding-right: 2em;
}

div#content.two-col #primary ol.number li .label {
    display: none;
}

div#content.two-col ol.alphaLowCase {
    list-style-type: lower-alpha;
}

div#content.two-col ul.content-list.alphaCaps .label, div#content.two-col ul.content-list.romanCaps .label {
    display: none;
}

div#content.two-col ul.content-list.alphaCaps > li {
    list-style-type: upper-alpha;
}

div#content.two-col ul.content-list.romanCaps > li {
    list-style-type: upper-roman;
}

div#content.two-col ol.content-list.alpha-caps li {
    list-style-type: none;
    padding-bottom: .5em;
}

div#content.two-col ol.content-list.alpha-caps li div, div#content.two-col ol.content-list.alpha-caps li p {
    display: inline;
}

div#content.two-col .address {
    font-style: italic;
    padding: 0 2em;
}

div#content.two-col .annotation {
    font-size: 90%;
}

div#content.two-col div.authorName {
    display: inline;
}

div#content.two-col div.newAuthor p {
    padding: 0em 1.5em;
}

div#content.two-col .caption {
    font-size: 90%;
    font-style: italic;
    padding-right: 4em;
}

div#content.two-col .figure {
    padding-left: 2em;
}

div#content.two-col .stanza {
    font-style: italic;
    text-indent: -1.5em;
    margin-left: 1.5em;
}

div#content.two-col .citationInfo {
    padding-left: 2em;
    text-align: right;
}

div#content.two-col .closingBlockContainer {
    text-align: right;
}

div#content.two-col .closingBlock {
    display: inline-block;
}

div#content.two-col .closingBlock .closing {
    padding-bottom: .5em;
}

div#content.two-col .closingBlock .signature {
    font-variant: small-caps;
}

div#content.two-col .closingBlock .office {
    padding-top: .5em;
}

div#content.two-col p.quote {
    padding: .5em 3em;
}

div#content.two-col .poetry {
    padding-left: 2em;
}

div#content.two-col .scriptureRef {
    font-style: italic;
}

div#content.two-col .figure .preamble {
    margin-left: -2em;
}

.sash-icons {
    display: none;
}

.lumen-template-read blockquote, div.body blockquote {
    margin: 0;
    border-left: none;
}

.lumen-template-read a, div.body a {
    color: #201500;
}

.lumen-template-read .label, div.body .label {
    background-color: #ffffff;
    color: #201500;
    padding: 0;
    font-weight: normal;
    line-height: single;
    text-shadow: none;
    font-size: 100%;
}

.lumen-template-read p, div.body p {
    margin: 0 0 1em;
}

.lumen-template-read li, div.body li {
    line-height: 1.4em;
}

.lumen-template-read ul, .lumen-template-read ol, div.body ul, div.body ol {
    margin: 0;
}

.lumen-template-read, div.body {
    font: -apple-system-body;
    margin: 0;
    padding: 2rem 4rem 4rem 4rem;
    background-color: #ffffff;
    color: #201500;
}

.lumen-template-read a:link, .lumen-template-read a:hover, .lumen-template-read a:visited, .lumen-template-read a:active,
div.body a:link, div.body a:hover, div.body a:visited, div.body a:active {
    color: blue;
}

.lumen-template-read a, div.body a {
    text-decoration: none;
}

.lumen-template-read .language, div.body .language {
    font-style: italic;
}

.lumen-template-read h1, div.body h1 {
    font: 36pt/42pt -apple-system-font;
    font-weight: 100;
    margin: 0;
    padding: .4em 0 .2em 0;
}

.lumen-template-read h2, div.body h2 {
    font-size: 117%;
}

.lumen-template-read h2.author, div.body h2.author {
    font: -apple-system-body;
    font-size: smaller;
    margin: 0;
    padding: 0;
}

.lumen-template-read div.topic h2, div.body div.topic h2 {
    text-align: center;
}

.lumen-template-read .strong, div.body .strong {
    font-weight: bold;
}

.lumen-template-read .emphasis, div.body .emphasis {
    font-style: italic;
}

.lumen-template-read .underline, div.body .underline {
    text-decoration: underline;
}

.lumen-template-read .small, div.body .small {
    font-size: 85%;
}

.lumen-template-read div.byline, div.body div.byline {
    padding-bottom: 1em;
}

.lumen-template-read div.byline p, div.body div.byline p {
    font-weight: bold;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

.lumen-template-read div.byline p+p, div.body div.byline p+p {
    font-weight: normal;
}

.lumen-template-read blockquote.intro, div.body blockquote.intro {
    max-width: 480px;
    xmargin: 0px auto -1px auto;
    padding: 1em 0;
    overflow: hidden;
}

.lumen-template-read img.img-decor, div.body img.img-decor {
    float: left;
    margin: 0 1em 1em 0;
}

.lumen-template-read .img-decor, .lumen-template-read div.figure > img, blockquote.intro > img,
div.body .img-decor, div.body div.figure > img {
    padding: 1px;
    background-color: white;
    border: 1px solid #9ea0a2;
    -moz-box-shadow: 2px 4px 13px #bbb;
    -webkit-box-shadow: 2px 4px 13px #bbb;
    box-shadow: 2px 4px 13px #bbb;
    border-image: initial;
    margin-bottom: 1em;
}

.lumen-template-read p.intro, div.body p.intro {
    font-family: serif;
    font-size: 130%;
    font-style: italic;
    color: #633600;
}

.lumen-template-read span.footnote, div.body span.footnote {
    font: -apple-system-body;
	background-color: #f0e4da;
	font-size: inherit;
}

.lumen-template-read #references li.footnote {
    font-size: smaller;
}

.lumen-template-read #references li {
    list-style: none;
    text-indent: -3em;
    margin-left: 3em;
}

div.body footer.notes li {
    text-indent: -3em;
    margin-left: 3em;
}

.lumen-template-read #references ol, div.body footer.notes ol {
    padding-left: 0px;
    -webkit-padding-start: 0px;
    padding-right: 2em;
}

.lumen-template-read #references .label, div.body footer.notes .label {
    display: inline-block;
    width: 2em;
    text-align: right;
}

.lumen-template-read #references p, div.body footer.notes p {
    text-indent: 0;
}

.lumen-template-read #references .label + p, div.body footer.notes .label + p {
    display: inline;
    padding-left: .1em;
    text-indent: -3em;
}

.lumen-template-read #primary ul.bullet {
    padding-right: 2em;
    padding-left: 2em;
}

.lumen-template-read #primary ul.bullet li .label {
    display: none;
}

.lumen-template-read ul.content-list.noMarker li {
    list-style-type: none;
}

.lumen-template-read .figure ul, .lumen-template-read .figure ol {
    -webkit-padding-start: 0;
}

.lumen-template-read #primary ol.number {
    padding-right: 2em;
}

.lumen-template-read #primary ol.number li .label {
    display: none;
}

.lumen-template-read ol.alphaLowCase {
    list-style-type: lower-alpha;
}

.lumen-template-read ul.content-list.alphaCaps .label, .lumen-template-read ul.content-list.romanCaps .label {
    display: none;
}

.lumen-template-read ul.content-list.alphaCaps > li {
    list-style-type: upper-alpha;
}

.lumen-template-read ul.content-list.romanCaps > li {
    list-style-type: upper-roman;
}

.lumen-template-read ol.content-list.alpha-caps li {
    list-style-type: none;
    padding-bottom: .5em;
}

.lumen-template-read ol.content-list.alpha-caps li div, .lumen-template-read ol.content-list.alpha-caps li p {
    display: inline;
}

.lumen-template-read .address, div.body .address {
    font-style: italic;
    padding: 0 2em;
}

.lumen-template-read .annotation, div.body .annotation {
    font-size: 90%;
}

.lumen-template-read div.authorName, div.body div.authorName {
    display: inline;
}

.lumen-template-read div.newAuthor p, div.body div.newAuthor {
    padding: 0em 1.5em;
}

.lumen-template-read .caption, div.body .caption {
    font-size: 90%;
    font-style: italic;
    padding-right: 4em;
}

.lumen-template-read .figure, div.body .figure {
    padding-left: 2em;
}

.lumen-template-read .stanza, div.body .stanza {
    font-style: italic;
    text-indent: -1.5em;
    margin-left: 1.5em;
}

.lumen-template-read .citationInfo, div.body .citationInfo {
    padding-left: 2em;
    text-align: right;
}

.lumen-template-read .closingBlockContainer, div.body .closingBlockContainer {
    text-align: right;
}

.lumen-template-read .closingBlock, div.body .closingBlock {
    display: inline-block;
}

.lumen-template-read .closingBlock .closing, div.body .closingBlock .closing {
    padding-bottom: .5em;
}

.lumen-template-read .closingBlock .signature, div.body .closingBlock .signature {
    font-variant: small-caps;
}

.lumen-template-read .closingBlock .office, div.body .closingBlock .office {
    padding-top: .5em;
}

.lumen-template-read p.quote, div.body p.quote {
    padding: .5em 3em;
}

.lumen-template-read .poetry, div.body .poetry {
    padding-left: 2em;
}

.lumen-template-read .scriptureRef, div.body .figure .scriptureRef {
    font-style: italic;
}

.lumen-template-read .figure .preamble, div.body .figure .preamble {
    margin-left: -2em;
}