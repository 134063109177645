/*
 * Main content
 */
 body {
    overflow-x: hidden;
}

/*
 * If we ever go back to regular scrollbars we'll need this to calculate their width
.scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
}
*/

a, a:link, a:visited {
    text-decoration: none;
    color: #0000c0;
}

a:hover, a:active {
    text-decoration: underline;
    color: blue;
}

/*
 * Disable webkit defaults
 */
ul {
    -webkit-padding-start: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
}

/* iOS specific css */
.iOS {
    -webkit-text-size-adjust: 100%;
}

.visiblelabel a, .visiblelabel a:link, .visiblelabel a:visited, .visiblelabel a:hover,
#scripcrumb a, #scripcrumb a:link, #scripcrumb a:visited,
#scicrumb a, #scicrumb a:link, #scicrumb a:visited {
    text-decoration: none;
    color: white;
}

#scripcrumb a:hover #scicrumb a:hover {
    text-decoration: underline;
    color: blue;
}

/* Tabs */
.tabBar {
    position: fixed;
    height: 3.5rem;
    left: 0;
    bottom: 0;
    z-index: 100;
    background-color: #3f2000;
    width: 280px;
}

ul.tabs {
    list-style: none;
    margin: 0;
}

li.tab {
    display: inline-block;
    width: 70px;
    height: 4rem;
    padding: 0px;
    position: relative;
}

#scicrumb li.tab a {
    position: absolute;
    width: 70px;
    height: 3rem;
    text-align: center;
    text-decoration: none;
    color: rgb(172,172,172);
    background-position: top;
    background-size: 25px;
    margin-top: .5em;
}

.tablabel {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-weight: normal;
    font-size: 10px;
}

li.citab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_citations.png) no-repeat;
}

#scicrumb li.citab.selectedtab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_citations_selected.png) no-repeat;
    background-position: top;
    background-size: 25px;
    color: white;
}

li.conttab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_contents.png) no-repeat;
    background-position: top;
    background-size: 25px;
}

#scicrumb li.conttab.selectedtab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_contents_selected.png) no-repeat;
    background-position: top;
    background-size: 25px;
    color: white;
}

li.speaktab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_speakers.png) no-repeat;
    background-position: top;
    background-size: 25px;
}

#scicrumb li.speaktab.selectedtab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_speakers_selected.png) no-repeat;
    background-position: top;
    background-size: 25px;
    color: white;
}

li.searchtab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_search.png) no-repeat;
    background-position: top;
    background-size: 25px;
}

#scicrumb li.searchtab.selectedtab a {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_search_selected.png) no-repeat;
    background-position: top;
    background-size: 25px;
    color: white;
}

/* side columns basic structure */
#citationindex, #citationindex2, #scriptures, #scriptures2 {
    position: absolute;
    top: 0;
    overflow-x: hidden;
    font-size: 12pt;
    font-weight: normal;
}

#citationindex, #citationindex2 {
    height: 100%;
    min-height: 100%;
    right: 0;
    font-family: Arial, Helvetica, sans-serif;
}

#scriptures, #scriptures2 {
    height: auto;
    left: 0;
    bottom: 0;
    font-family: -apple-system-body, Palatino, 'Times New Roman', Times, serif;
}

.scripturewrapper, .sciwrapper {
    position: absolute;
    background-color: white;
    color: #201500;
    width: auto;
    height: auto;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    max-height: 9999999px;
}

.scripturecontent, .scicontent {
    width: 100%;
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    height: auto;
    top: 3rem;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(236,226,217);
}

.scicontent, .scripturecontent {
    top: 3rem;
    bottom: 3.5rem;
}

#scriptureverses {
    background-color: white;
}

.loadingleft, .loadingright {
    position: absolute;
    top: .3rem;
}

.loadingright {
    right: .3rem;
}

.loadingleft {
    left: .3rem;
}

#blockContentLeft, #blockContentRight {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/transparent.png);
    top: 3rem;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
}

.blockContentButton {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/transparent.png);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: transparent;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
}

/* breadcrumb bar buttons */
.toggleVisibilityRight,
.toggleVisibilityLeft {
    position: absolute;
    top:0;
    z-index: 20;
}

.toggleVisibilityLeft {
    right: 0;
}

.toggleVisibilityRight {
    left: 0;
}

.sidenavLeft {
    display: inline-block;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/sidenav-left-15x30.png) no-repeat;
    background-position: 0 0;
    background-size: 15px 30px;
    height: 30px;
    margin: 2px;
    margin-top: 7px;
    border: 1px solid transparent;
    padding: 1px 5px 1px 9px;
    z-index: 20;
}

.sidenavRight {
    display: inline-block;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/sidenav-right-15x30.png) no-repeat;
    background-position: 0 0;
    background-size: 15px 30px;
    height: 30px;
    margin: 2px;
    margin-top: 7px;
    border: 1px solid transparent;
    padding: 1px 5px 1px 9px;
    z-index: 20;
}

#citationindex .resources, #citationindex2 .resources,
#centercolumn2 .menu, #centercolumn .menu,
.scripturesleftarrow, .scripturesrightarrow,
.tpjsrightarrow, .tpjsleftarrow {
    display: inline-block;
    width: 18px;
    height: 19px;
    margin: 2px;
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    z-index: 20;
}

.resources {
    display: inline-block;
    width: 18px;
    height: 19px;
    margin: 2px;
    border: 1px solid transparent;
    position: absolute;
    top: 0;
    z-index: 20;
}

.tpjsrightarrow, .scripturesrightarrow {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/right-6x.png) no-repeat;
    background-size: 13px 13px;
    background-position: 4px 4px;
    right: 0;
    top: .7em;
}

.tpjsleftarrow, .scripturesleftarrow {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/left-6x.png) no-repeat;
    background-size: 13px 13px;
    background-position: 4px 4px;
    top: .7em;
}

#centercolumn .menu, #centercolumn2 .menu,
#citationindex .resources, #citationindex2 .resources {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/menu-13x13-6x.png) no-repeat;
    background-size: 15px 15px;
    background-position: 0 0;
    right: 0;
    margin-top: 14px;
    margin-right: 8px;
}

.resources {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/menu-13x13-6x.png) no-repeat;
    background-size: 15px 15px;
    background-position: 0 0;
    right: 0;
    margin-top: 14px;
    margin-right: 8px;
}

.sidenavRight:hover, .sidenavLeft:hover, .tpjsrightarrow:hover, .tpjsleftarrow:hover,
.scripturesrightarrow:hover, .scripturesleftarrow:hover,
#citationindex a.resources:hover,
#citationindex a.menu:hover,
#centercolumn2 a.menu:hover,
#centercolumn2 a.resources:hover,
#centercolumn a.menu:hover,
#centercolumn a.resources:hover,
#citationindex2 a.resources:hover,
#citationindex2 a.menu:hover {
    border: 1px solid rgb(160, 140, 106);
    border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
}

a.resources:hover {
    border: 1px solid rgb(160, 140, 106);
    border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    -webkit-border-radius: 0px 0px 0px 0px;
}

#centercolumn2 a.menu:focus,
#centercolumn a.menu:focus,
#citationindex a.resources:focus,
#citationindex2 a.resources:focus {
    outline: none;
}

a.resources:focus {
    outline: none;
}

#citationindex ul.nav, #citationindex2 ul.nav, #centercolumn2 ul.nav, #centercolumn ul.nav {
    top: 23px;
    right: 2px;
    left: auto;
}

#centercolumn2 ul.drop4, #centercolumn ul.drop4 {
    right: 20px;
}


/*
 * Breadcrumbs
 */
#scicrumb .crumb {
    overflow: hidden;
}

#scripcrumb, #scicrumb {
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    padding: 0rem .5rem;
    color: white;
    background-color: #3f2000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
    height: 3rem;
}

#scicrumb {
    padding-left: 23px;
}

#scicrumb.citation_crumb {
    padding-right: 30px;
}

ul.crumb {
    padding: .9rem 0rem;
    margin: 0;
    margin-right: .5em;
}

ul.nav_crumb {
    margin-right: 4em;
}

ul.crumb > li {
    display: inline-block;
}

li.acrumb {
    padding-right: 9px;
    margin-right: 3px;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/right-6x.png) no-repeat;
    background-size: 6px, 7px;
    background-position: right 0.5em;
}

.rightarrow, .leftarrow {
    display: inline-block;
    margin-bottom: 0.1em;
    margin-left: 0.3em;
    margin-right: 0.1em;
    width: 6px;
    height: 7px;
}

.rightarrow {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/right.png) no-repeat;
}

.leftarrow {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/left.png) no-repeat;
}

/* sidecolumn styling */
    /* basic styling of box */
ul.talksblock, ul.topiclist, ul.discoursesblock, ul.referencesblock,
ul.volumecontents, ul.chaptersblock, ul.conflist, ul.searchresults,
ul.speakerlist, ul.jodvolumes, ul.pagesblock, ul.speakerletters, ul.contentlist {
    list-style: none;
    padding: 0;
    margin: 0;
}

.scicontent ul.discoursesblock li, .sciwrapper ul.referencesblock li,
.scicontent ul.talksblock li, .scicontent ul.topiclist li, ul.searchresults li,
.scripturecontent ul.volumecontents li, .scripturecontent ul.chaptersblock li,
.scicontent ul.volumecontents li, .scicontent ul.chaptersblock li, ul.conflist li,
ul.speakerlist li, ul.jodvolumes li, ul.pagesblock li, ul.speakerletters li,
ul.contentlist li {
    list-style: none;
    display: inline-block;
    min-height: 33px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    text-shadow: 2px 0px 2px rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    border: solid 5px;
    border-image: url(https://scriptures.byu.edu/static/homepage/media/Images.xcassets/Images.xcassets/CellBackground.imageset/CellBackground@2x.png) 7 stretch;
}

.scicontent ul.discoursesblock li, .sciwrapper ul.referencesblock li,
.scicontent ul.talksblock li, .scicontent ul.topiclist li, .scicontent ul.searchresults li,
.scicontent ul.contentlist li, .scicontent ul.speakerletters li {
    position: relative;
    min-height: 40px;
    text-align: left;
}

ul.speakerlist li {
    text-align: left;
    padding-left: 1em;
}

.scicontent ul.chaptersblock li, .scicontent ul.volumecontents li {
    min-height: 45px;
}

ul.pagesblock li, ul.jodvolumes li.grid {
    width: 24.24%;
}

ul.chaptersblock li {
    width: 19.2%;
}

.sciwrapper li.grid {
    width: 19.48%;
    min-height: 45px;
}

.sciwrapper .jodvolumes li.grid {
    min-height: 33px;
}

.scicontent ul.chaptersblock li.disabled, .scicontent ul.volumecontents li.disabled,
.scicontent ul.chaptersblock li.disabled .citationcount,
.scicontent ul.chaptersblock li.disabled .citationcount:hover,
.scicontent ul.volumecontents li.disabled .citationcount:hover,
.scicontent ul.volumecontents li.disabled .citationcount {
    color: #bbb;
    text-shadow: 0px 0px 0px;
    background-color: white;
}

.scicontent .disabledwrapper {
    width: 100%;
    min-height: 40px;
    border: 1px solid transparent;
    padding-top: 5px;
    display: inline-block;
}

ul.referencesblock li:active, ul.talksblock li:active,
ul.discoursesblock li:active, ul.topiclist li:active,
ul.searchresults li:active, ul.speakerletters li:active,
ul.volumecontents li:active, ul.chaptersblock li:active,
ul.conflist li:active, ul.speakerlist li:active,
ul.contentlist li:active,
ul.jodvolumes li:active, ul.pagesblock li:active {
    background-color: #ffffcc;
    border-image: url(https://scriptures.byu.edu/static/homepage/media/Images.xcassets/Images.xcassets/CellBackground.imageset/CellBackgroundClicked@2x.png) 7 stretch;
}

ul.referencesblock a, ul.referencesblock a:link, ul.referencesblock a:visited,
ul.talksblock a, ul.talksblock a:link, ul.talksblock a:visited,
ul.discoursesblock a, ul.discoursesblock a:link, ul.discoursesblock a:visited,
ul.topiclist a, ul.topiclist a:link, ul.topiclist a:visited,
ul.speakerletters a, ul.speakerletters a:link, ul.speakerletters a:visited,
ul.searchresults a, ul.searchresults a:link, ul.searchresults a:visited,
ul.volumecontents a, ul.volumecontents a:link, ul.volumecontents a:visited,
ul.chaptersblock a, ul.chaptersblock a:link, ul.chaptersblock a:visited,
ul.conflist a, ul.conflist a:link, ul.conflist a:visited,
ul.contentlist a, ul.contentlist a:link, ul.contentlist a:visited,
ul.speakerlist a, ul.speakerlist a:link, ul.speakerlist a:visited,
ul.jodvolumes a, ul.jodvolumes a:link, ul.jodvolumes a:visited,
ul.pagesblock a, ul.pagesblock a:link, ul.pagesblock a:visited {
    text-decoration: none;
    color: #444;
}

.scicontent ul.volumecontents li a, .scicontent ul.chaptersblock li a,
ul.conflist li a, ul.speakerlist li a, ul.jodvolumes li a, ul.pagesblock li a,
.scripturecontent ul.volumecontents li a, .scripturecontent ul.chaptersblock li a,
ul.talksblock li a, .scicontent ul.referencesblock li a,
ul.contentlist li a, .scicontent ul.searchresults li a,
.scicontent ul.topiclist li a, .scicontent ul.speakerletters li a,
ul.discoursesblock li a {
    display: inline-block;
    width: 100%;
    min-height: 40px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid transparent;
    padding: 0;
    margin: 0;
    color: rgb(0, 0, 0);
    font-size: 16px;
    text-shadow: 2px 0px 2px rgb(255, 255, 255);
}

.scripturecontent ul.volumecontents li a, .scripturecontent ul.chaptersblock li a {
    min-height: 30px;
    padding-top: 7px;
}

.scicontent ul.volumecontents li a, .scicontent ul.chaptersblock li a {
    text-align: center;
    padding-top: 5px;
}

ul.conflist li a, ul.speakerlist li a, ul.jodvolumes li a, ul.pagesblock li a {
    min-height: 30px;
}

ul.conflist li a, ul.speakerlist li a, ul.pagesblock li a {
    padding-top: .5em;
}

ul.jodvolumes li a {
    padding-top: .6em;
}

.scicontent ul.chaptersblock li, .scicontent ul.volumecontents li, .scicontent ul.jodvolumes li {
    min-height: 45px;
}

.scicontent ul.contentlist li a.oneline, .scicontent ul.speakerletters li a.oneline,
.scicontent ul.referencesblock li a.oneline, .scicontent ul.topiclist li a.oneline {
    min-height: 30px;
    border: 1px solid transparent;
    width: 100%;
    display: inline-block;
}

.scicontent ul.topiclist li a.oneline,
.scicontent ul.speakerletters li a.oneline {
    padding-top: .3em;
}

.scicontent ul.topiclist li a.onelineRemoved {
    padding-top: 0;
    padding-bottom: .25em;
}

    /* dividers */
div.volumetitle, b.chaptertitle, div.sessiontitle, div.sectiontitle {
    display: block;
    background-color: rgb(236,226,217);
    padding: .5em;
    text-align: center;
    color: black;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

div.volumetitle {
    text-align: left;
}

    /* jod topical index image */
.arrow {
    position: absolute;
    right: 15px;
    left: auto;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/arrow-13x13.png) no-repeat;
    width: 20px;
    height: 100%;
    bottom: 0;
    background-position: center;
}

/* contents icons */
.gc_icon{
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_gc.png) no-repeat;
}

.jd_icon{
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_jod.png) no-repeat;
}

.ti_icon{
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_tg.png) no-repeat;
}

.tpjs_icon{
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_tpjs.png) no-repeat;
}

.gc_icon, .jd_icon, .ti_icon, .tpjs_icon{
    width: 57px;
    height: 57px;
    background-position: center;
    background-size: contain;
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
}

.contentlist .contentwrapper {
    height: 67px;
    display: inline-block;
    position: absolute;
    padding-top: .75em;
}

.scicontent ul.contentlist .subtitle{
    padding-left: .5rem;
}

    /* watch and listen to talk buttons */
ul.talksblock li .listentalk,
ul.referencesblock li .listentalk,
ul.talksblock li .watchtalk,
ul.referencesblock li .watchtalk {
    font-size: .6em;
    color: #444;
    text-align: center;
    position: absolute;
    opacity: 1;
    left: 4px;
    bottom: 0;
}

ul.talksblock li a.listenlink,
ul.referencesblock li a.listenlink,
ul.talksblock li a.watchlink,
ul.referencesblock li a.watchlink {
    position: absolute;
    width: 40px;
    top: 0;
    z-index: 2;
    border: none;
}

ul.talksblock li a.listenlink,
ul.referencesblock li a.listenlink {
    right: 10px;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/soundIcon.png) no-repeat;
    background-position: 11.5px 5px;
}

ul.talksblock li a.watchlink,
ul.referencesblock li a.watchlink {
    right: 50px;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/filmIcon.png) no-repeat;
    background-position: 12.5px 7px;
}

    /* listing Titles */
.scicontent ul.contentlist li a.oneline .topictitle {
    padding-right: 30px;
}
.scicontent ul.speakerletters li a.oneline .speakerletter,
.scicontent ul.topiclist li a.oneline .topictitle {
    padding-right: 30px;
    padding-top: .25em;
}

.scicontent ul.talksblock li .speaker,
.scicontent ul.searchresults li .resultTitle,
.scicontent ul.discoursesblock li .speaker {
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: .1em;
}

.scicontent ul.contentlist li .topictitle,
.scicontent ul.speakerletters li .speakerletter,
.scicontent ul.topiclist li .topictitle {
    display: inline-block;
    padding-left: 0.5em;
    padding-right: 3px;
    padding-top: .1em;
}

.scicontent ul.referencesblock li .reference {
    display: inline-block;
    padding-left: 0.5em;
    padding-top: 0.5em;
}


    /* listing subtitles */
li.grid .citationcount {
    font-size: 75%;
    color: #444;
    padding: 0;
    margin-top: -0.2em;
}

.vol_citation {
    font-weight: normal;
    color: #444;
    font-size: .75em;
    display: inline-block;
    position: absolute;
    right: 1em;
}

.scicontent .citationcount, .scicontent .talktitle, .scicontent .resultContext,
.scicontent .topicsubtitle, .scicontent .subtitle {
    font-size: .75em;
    color: #444;
    line-height: 15px;
}

.scicontent .resultContext {
    padding-right: 1em
}

.scicontent ul.topiclist li .topicsubtitle {
    padding-left: 0.66em;
    overflow: hidden;
    padding-right: 3px;
    padding-bottom: .25em;
}

.scicontent ul.talksblock li .talktitle, .scicontent ul.discoursesblock li .talktitle,
.scicontent ul.searchresults li .resultContext,
.scicontent ul.referencesblock li .talktitle,
.scicontent ul.contentblock li .subtitle {
    padding-left: 0.66em;
    overflow: hidden;
    padding-bottom: .25em;
}

.scicontent ul.talksblock li .oneline {
    padding-top: .5em;
}

.scicontent ul.referencesblock li .citationcount {
    padding-left: .75em;
    display: inline-block;
}

.sciwrapper .talktitlelisten, .sciwrapper .referencelisten {
    margin-right: 50px;
}

.sciwrapper .talktitlewatch, .sciwrapper .referencewatch {
    margin-right: 90px;
}

    /* Query results */
.qSummary, .filter_message {
    padding: 3px;
    padding-right: 10px;
    background-color: #ffffcc;
    font-family: Arial, Helvetica, sans-serif;
}

.currentResultSet {
    color: black;
    font-weight: bold;
    padding-right: 1px;
    padding-left: 1px;
}

.resultsNav a{
    padding-right: 1px;
    padding-left: 1px;
    text-decoration: underline;
}

.resultsNav {
    margin: 0;
    padding: 3px;
    background-color: #f0e4da;
    text-align: center;
}

    /* Filter Results */
div.filtersummary {
    position:fixed;
}

.filtersummarywrapper {
    position: relative;
    width: 100%;
}

.filtertitle, .filterlabel {
    font-weight: bold;
}

ul.filterlist {
    margin: 0;
    list-style: none;
}

.filter_btn {
    text-align: right;
}

.clearFilterButton {
    margin-right: 2px;
    margin-left: 2px;
    color: blue;
}

.filter_line{
    display: block;
}

    /* Advanced Search Form and Filter Form*/
#resetAdvFormBtn, #resetFilter {
    margin-left: 0.5em;
}

.advTitle, .filterTitle {
    text-align: center;
    padding: 0;
    margin: 0;
    text-indent: 0;
    padding-bottom: 6px;
    font-weight: bold;
}

.advTalks {
    padding: 0;
    margin: 0;
    text-indent: 0;
    position: relative;
}

.advTalks a, #searchScriptures a {
    font-size: 14px;
    display: block;
}

form {
    padding: 0;
    margin: 0;
    text-indent: 0;
}

.asInput {
    margin-top: 4px;
    vertical-align: middle;
}

.to {
    display: inline-block;
    padding: 0.5em;
    position: relative;
    top: -0.2em;
}

#searchTalks, #searchScriptures,
#searchWhatWrapper, #frmFilter {
    padding: .75rem;
}

#speakerSearchWrapper, #speakerFilterWrapper,
.filterCorpusWrapper, .filterGroupByWrapper,
.filterSortByWrapper, .filterButtonWrapper,
#searchInput, #searchWhatWrapper,
.searchBtnWrapper, .noResults {
    text-align: center;
}

#yearsSearch, #yearsFilter {
    text-align: center;
}

#advSearchButton, #filterButton {
    float: right;
    padding: 0;
    margin: 2px;
    vertical-align: middle;
}

/* scriptures styling */
.scripturewrapper blockquote {
    border-left: none;
}

/*
 * Chapter contents
 */
div.chapterheading {
    font-size: 14pt;
    font-weight: bold;
    padding: .5em;
    text-align: center;
    line-height: 1.2;
}

div.navheading {
    font-size: 13pt;
    text-align: center;
}

ul.versesblock {
    margin: 0;
    line-height: auto;
    list-style: none;
    padding: .75em;
    padding-right: 25%;
    padding-left: 25%;
    font-size: 13pt
}

ul.versesblock li {
    position: relative;
    display: block;
    margin-bottom: .3em;
}

ul.versesblock li.sectionHeader {
    font-style: italic;
}

span.verse {
    padding-left: .4em;
    padding-right: .3em;
    font-weight: bold;
}

/*
 * Navigation links
 */
div.navlinks {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    font-weight: bold;
    padding: 0 .5rem;
    height: 1.2rem;
}

a.prevnav {
    position: absolute;
    left: .5rem;
    padding-top: .2rem;
    border: 1px solid #c0c0c0;
    background-color: #ece2d9;
    border-radius: .3em;
    -moz-border-radius: .3em;
    -webkit-border-radius: .3em;
    padding-left: .3em;
    padding-right: .3em;
    margin-bottom: 1em;
}

a.nextnav {
    position: absolute;
    right: .5rem;
    padding-top: .2rem;
    border: 1px solid #c0c0c0;
    background-color: #ece2d9;
    border-radius: .3em;
    -moz-border-radius: .3em;
    -webkit-border-radius: .3em;
    padding-left: .3em;
    padding-right: .3em;
}

a.nextnav > img, a.prevnav img {
    padding-bottom: .1rem;
}

div.navlinks a, div.navlinks a:link, div.navlinks a:visited {
    text-decoration: none;
    color: #444;
}

div.navlinks a:hover {
    text-decoration: underline;
    color: blue;
}

div.bottomnavlinks {
    padding-bottom: 1em;
}

/*
 * Highlighted target verses, i.e. from following hyperlink like D&C 27:12
 */
li.match {
    background-color: #ffffcc;
}

/*
 * Verse sharing popups
 */
.share-popup {
    position: absolute;
    width: 130px;
    left: 1.4em;
    top: 0;
    margin-right: -135px;
    margin-top: -0.53em;
    background: #eee;
    box-shadow: 1px 1px 9px rgba(0,0,0,0.2);
    border-radius: 5px;
    text-align: center;
    vertical-align: middle;
    padding: .15em .15em 0 .15em;
    opacity: 0.95;
    border: 1px solid #ccc;
    z-index: 20;
}

.share-popup-point {
    position: absolute;
    top: 20px;
    left: -13px;
    width: 1px;
    height: 1px;
    margin-top: -8px;
    border-right: 12px solid #ccc;
    border-top: 8px solid rgba(0,0,0,0);
    border-bottom: 8px solid rgba(0,0,0,0);
}

.share-popup-point.light {
    left: -12px;
    border-right: 12px solid #eee;
}

.share-text {
    position: relative;
    top: -0.6em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
    font-weight: bold;
    color: #222;
}

.share-popup img.share-icon {
    margin-right: 0.3em;
}

.share-popup img.share-icon:hover {
    cursor: hand;
}


/* Center Column Styling */
    /* Basic structure */
#centercontent {
    position: absolute;
    top: 3rem;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

#centercolumn, #centercolumn2 {
    position: absolute;
    overflow: hidden;
    overflow-x: hidden;
    top: 0;
    height:100%;
    min-height:100%;
    z-index: 9;
    background-color: #f5f5f5;
    border-right: 1px solid rgb(160,140,106);
    border-left: 1px solid rgb(160,140,106);
}

.centerLeftHidden {
    left: 23px;
}

#talkcontent, #mapcontent, #googleearthcontent, #studyjournalcontent {
    background-color: #ffffff;
    position: absolute;
    max-height: 9999999px;
    opacity: 1;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

    /* Navigation bar, buttons, and labels */
#centernavbar button.print_button {
    position: absolute;
    width: 30px;
    height: 3rem;
    right: 2.5em;
    border: 0;
    background: url(https://scriptures.byu.edu/static/homepage/media/images/printer.png) no-repeat;
    background-position: center;
    background-size: 20px;
}

#centernavbar {
    position: absolute;
    width: 100%;
    color: white;
    background-color: #3f2000;
    height: 3rem;
}


#hideciteconf, #hidenoteconf, #hidereferences{
    display: block;
}

#showciteconf, #shownoteconf, #showreferences{
    display: none;
}

#tpjsnavdivs, #scripturenavdiv {
    position: absolute;
    width: 44px;
    right: 5em;
}

#scripturenavdiv {
    top: 0;
    right: 1.5em;
}

.largelabel {
    display: inline;
}

.smalllabel {
    display: none;
}

.hiddenlabel {
    display: none;
}

.hiddenlargelabel, .hiddensmalllabel {
    display: none;
}

.visiblelabel {
    display: inline-block;
    padding: .9rem .5rem;
    margin: 0;
    position: absolute;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
}

#talklabel {
    padding-right: 24px;
    white-space: nowrap;
    overflow: hidden;
    left: 0;
    right: 3em;
}

#talklabel.tpjslabel {
    right: 7em;
}

#talklabel.multiline_info, ul.multiline_crumb {
    padding-top: .4em;
    white-space: normal;
}

/*ABOUT */
#aboutwrapper p {
    text-indent: 0;
}

#talkwrapper h2 {
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
}

h3 {
    margin-top: .2em;
    margin-bottom: .2em;
}

#aboutwrapper td, #aboutwrapper th {
    padding: 0;
}

.aboutwrapper {
    padding: 0 1.25em;
}

/* JOD MARKCITES */

div.citeInfo {
    display: none;
}

.contentBlocker {
    height: 100%;
    width: 100%;
    background-color: grey;
    z-index: 1000000;
    position: absolute;
    opacity: .5;
}

.pagination-small ul > li > a, .pagination-small ul > li > span {
    padding: 2px 5px;
}

/* filter dialog */

#citationindex .open_filter_btn, #citationindex2 .open_filter_btn {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_filter_selected.png) no-repeat;
    background-size: contain;
}

.open_filter_btn {
    background: url(https://scriptures.byu.edu/static/homepage/media/images/icon_filter_selected.png) no-repeat;
    background-size: contain;
}

#filter_dialog, #speaker_dialog {
    display: none;
    color: #333333;
}

#scifilter {
    max-height: 500px;
}

#filter_dialog.filter_shown, #speaker_dialog.speaker_shown {
    width: 270px;
    left: 0;
    right: auto;
    top: 3rem;
    min-height: 400px;
    margin: 3px;
    display: block;
}

.modal-backdrop {
    right: auto;
    width: 280px;
    opacity: .5;
}

#print_div {
    display: none;
}

.dropdown-menu > li > button {
    padding: 3px 20px;
    min-width: 100%;
    text-align: left;
    color: #333333;
    font-weight: normal;
    line-height: 20px;
    clear: both;
    border: none;
    background-color: inherit;
    white-space: nowrap;
}

.dropdown-menu > li > button:hover {
    color: white;
    background-color: #0088cc;
}

/* Custom Styles */
.centerscrip {
    font-family: -apple-system-body, Palatino, 'Times New Roman', Times, serif;
    background-color: white;
}

b.small {
    font-weight: normal;
    font-size: 10.5pt;
}

.citnoresults {
    text-align: center;
    margin: 2rem;
}

/* media calls */
@media (min-width: 1200px) {

    #scicrumb li.tab a {
        font-size: inherit;
    }

    #scicrumb li.tab, #scicrumb li.tab a {
        width: 100px;
    }

    .modal-backdrop, .tabBar {
        width: 400px;
    }

    #filter_dialog.filter_shown, #speaker_dialog.speaker_shown {
        width: 390px;
    }

    .scicontent ul.discoursesblock li, .sciwrapper ul.referencesblock li,
    .scicontent ul.talksblock li, .scicontent ul.topiclist li, .scicontent ul.searchresults li,
    .scicontent ul.speakerletters li, .scicontent ul.contentlist li {
        width: 390px;
    }

    .sidecolumn {
        width: 400px;
    }
    ul.speakerlist li {
        width: 390px;
    }
    ul.conflist li {
        width: 190px;
    }

    ul.volumecontents li {
        width: 123.333333px;
    }

    ul.volumecontents li.grid, ul.jodvolumes li.grid, ul.pagesblock li {
        width: 70px;
    }

    ul.chaptersblock li {
        width: 47.142857px;
    }

    div.navlinks {
        height: 0;
    }

    #centercolumn, #centercolumn2 {
        left: 400px;
        right: 0px;
    }

    #centercolumn.centerLeftHidden, #centercolumn2.centerLeftHidden {
        left: 23px;
    }

    #centercolumn.centerRightHidden, #centercolumn2.centerRightHidden {
        right: 23px;
    }

    #citationindex2.hiddenRight, #citationindex.hiddenRight {
        right: -377px;
    }

    #scriptures2.hiddenLeft, #scriptures.hiddenLeft {
        left: -377px;
    }

    div.filtersummary {
        width: 394px;
    }

    #scicrumb .hiddencrumb, #scripcrumb .hiddencrumb{
        display: none;
    }
}

@media (max-width: 1199px) {
    .resultsNav a.hidesmall{
        display: none;
    }

    .scicontent ul.discoursesblock li, .sciwrapper ul.referencesblock li, .scicontent ul.speakerletters li,
    .scicontent ul.talksblock li, .scicontent ul.topiclist li, .scicontent ul.searchresults li,
    .scicontent ul.contentlist li {
        width: 270px;
    }

    .sidecolumn {
        width: 280px;
    }

    ul.speakerlist li {
        width: 270px;
    }

    ul.volumecontents li, ul.conflist li {
        width: 130px;
    }

    ul.volumecontents li.grid {
        width: 83.333333333px;
    }

    ul.jodvolumes li.grid, ul.pagesblock li {
        width: 60px;
    }

    ul.chaptersblock li {
        width: 46px;
    }

    div.navlinks {
        height: 2em;
    }

    #centercolumn, #centercolumn2 {
        left: 280px;
        right: 0px;
    }

    #centercolumn.centerLeftHidden, #centercolumn2.centerLeftHidden {
        left: 23px;
    }

    #centercolumn.centerRightHidden, #centercolumn2.centerRightHidden {
        right: 23px;
    }

    #citationindex2.hiddenRight, #citationindex.hiddenRight {
        right: -257px;
    }

    #scriptures2.hiddenLeft, #scriptures.hiddenLeft {
        left: -257px;
    }

    ul.conflist li span.extendedname {
        display: none;
    }

    #advQueryInput {
        height: 69px;
    }

    #advancedField {
        top: 35px;
    }

    #scicrumb .hiddencrumb, #scripcrumb .hiddencrumb{
        display: none;
    }
}

@media print {
    #body_content {
        display: none;
    }
    #print_div {
        display: block;
    }
    #showreferences, #hidereferences {
        display: none;
    }
}