div.page .citation > a[onclick*="sx("] {
    background-size: 1.4em 1.08em;
}

div.page {
    font: -apple-system-body;
    margin: 0;
    display: block;
    text-align: left;
    padding: 0 2em 4em 2em;
    background-color: #ffffff;
    color: #201500;
}

div.page a:link, div.page a:hover, div.page a:visited, div.page a:active {
    color: blue;
}

div.page div.prev, div.page div.next, div.page div.pageNum {
    display: none;
}

div.page p {
    display: block;
    margin: .5em;
    text-indent: 1.5em;
}

div.page p.right {
    display: block;
    margin: .5em;
    text-align: right;
}

div.page p.salutation {
    display: block;
    margin: .5em;
    text-indent: 0;
    width: 100%;
}

div.page div.salutation {
    display: inline;
    margin-left: .5em;
}

div.page div.placeDate {
    display: inline;
    float: right;
    margin-right: 20px;
}

div.page p.cont {
    display: block;
    margin: .5em;
    text-indent: 0;
}

div.page p.blockTitle {
    display: block;
    margin: 20px;
    margin-left: 15%;
    margin-right: 15%;
    text-align: left;
    text-indent: 0;
}

div.page p.centerTitle {
    display: block;
    margin: 20px;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
    text-indent: 0;
}

div.page p.wideEllipse {
    display: block;
    margin: 20px;
    text-align: center;
}

div.page p.summary {
    display: block;
    margin: .5em;
    font-size: 85%;
    line-height: 125%;
}

div.page i {
    font-style: italic;
}

div.page div.topic {
    display: block;
    text-align: center;
    font-weight: bold;
    width: 100%;
    margin: .5em;
}

div.page div.footnotes {
    display: block;
    text-align: left;
    margin: .5em;
    font-size: 90%;
}

div.page div.footnotes:before {
    display: block;
    width: 25%;
    content: " ";
    border-top: 1px solid black;
}

div.page .textMark {
    vertical-align: super;
    font-size: 90%;
    padding-right: .3em;
}

div.page div.textFootnotes:before {
    display: block;
    width: 25%;
    content: " ";
    border-top: 1px solid black;
}

div.page div.textFootnotes {
    display: block;
    margin-top: .5em;
    margin-bottom: .5em;
    font-size: 90%;
}

div.page .dominant {
    font-size: 160%;
}

div.page .subordinate {
    font-size: 130%;
}

div.page .h1 {
    font-size: 140%;
}

div.page .centerEmphasis {
    display: block;
    margin: .5em;
    font-style: italic;
    font-weight: normal;
}

div.page span.footRef {
    vertical-align: super;
    font-size: 70%;
}

div.page span.textFootRef {
    display: inline;
    vertical-align: super;
    font-size: 80%;
}

div.page div.footnote:first-child {
    padding-top: .5em;
}

div.page div.footnote {
    display: block;
    padding-left: 25px;
    text-indent: -25px;
}

div.page br {
}

div.page p.poem {
    margin-left: 30%;
    margin-right: 30%;
    text-align: left;
    font-size: 90%;
    text-indent: 0;
}

div.page hr.centerQuarter {
    margin-left: 37%;
    margin-right: 37%;
    border-top: 1px solid black;
    text-align: center;
}

div.page div.signatureBlock {
    display: block;
    margin: .5em;
}

div.page p.signatureLine {
    display: block;
    margin: .5em;
    margin-top: 0;
    margin-bottom: 0;
}

div.page a.scriptureRef {
}

div.page a {
    text-decoration: none;
}

div.page #dest {
    color: red;
}

div.page .biblio {
    padding-left: 2.5em;
    padding-right: 2.5em;
}