.nav-unit-enter {
    opacity: 0;
}
.nav-unit-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}
.nav-unit-exit {
    opacity: 1;
}
.nav-unit-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}

.cont-trans-enter {
    opacity: 0;
}
.cont-trans-enter-active {
    opacity: 1;
    transition: opacity 4000ms;
}
.cont-trans-exit {
    opacity: 1;
}
.cont-trans-exit-active {
    opacity: 0;
    transition: opacity 4000ms;
}